export const selfFacts = [
  "believes in digital intimacy",
  "unironically enjoys hyperpop",
  "is using tetris as therapy",
  "is organizing her goodreads bookshelves",
  "is overthinking her playlist covers",
  "psychoanalyzes to feel safe",
  "is fascinated by abnormal psychology",
  "is deep in a wikipedia rabbit hole",
  "has gone permanently nocturnal",
  "is outfit repeating",
  "is a believer in retail therapy",
  "is reading about creation myths",
  "is a chronic internet lurker",
  "is writing spaghetti code",
  "is making half-finished games",
  "is chilling on figma with friends",
  "is watching a movie on 2x speed",
  "only eats one food (hotpot)",
  "is doodling in ms paint",
  "is dissociating at indie concerts",
  "is trauma dumping on strangers",
  "is intellectualizing all feeling",
  "is adding 花椒 to everything",
  "is giving stick n poke tattoos",
  "is raving about 2cb",
  "is experimenting with drug states",
  "aims to create safe spaces",
  "is a shill for dimensional",
  "collects tamagotchis",
  "is attached to her notes app",
  "is writing fanfic abt inanimate objects",
  "is writing fanfic about her friends",
  "is writing fanfic about OTPs",
  "is writing the most neurotic autofiction",
  "is always misplacing their keys",
  "is annotating lyrics on genius",
  "will always use messenger over imessage",
  "is engaging in reddit debates",
  "can always be found in bed",
  "is hyperfixated on people",
  "is hyperfixated on narratives",
  "is always running 30 minutes late",
];

export const quotes = [
  [
    "I am made and remade continually. Different people draw different words from me.",
    "https://www.goodreads.com/quotes/451044-i-am-made-and-remade-continually-different-people-draw-different",
  ],
  [
    "We took away your art because we thought it would reveal your souls.",
    "https://www.goodreads.com/quotes/269882-we-took-away-your-art-because-we-thought-it-would",
  ],
  [
    "So we spent what was left of our serotonin / To chew on our cheeks and stare at the moon",
    "https://genius.com/Phoebe-bridgers-graceland-too-lyrics",
  ],
  [
    "You treat me like I'm more than a pair of skin and bones / And that really made a difference in my story",
    "https://genius.com/070-shake-skin-and-bones-lyrics",
  ],
  [
    "Books exist fully only in the act of being read; and its real home is inside the head of the reader... books are solitudes where we meet",
    "https://www.goodreads.com/book/show/16158561-the-faraway-nearby",
  ],
  [
    "But these things don't matter, because once you are Real you can't be ugly, except to people who don't understand",
    "https://www.goodreads.com/book/show/144974.The_Velveteen_Rabbit",
  ],
  [
    "I am good at falling in love and I can survive falling out of it. I’m good at falling",
    "https://genius.com/The-japanese-house-lilo-lyrics",
  ],
  [
    "I brushed up on philosophy: To live is to exist within time. To remember is to negate time.",
    "https://granta.com/los-angeles-ma/",
  ],
  [
    "For many years, I have been moved by the blue at the edge of what can be seen [...] Blue is the color of longing for distances you never arrive in",
    "https://www.goodreads.com/book/show/76479.A_Field_Guide_to_Getting_Lost",
  ],
  [
    "You are a woman with a man inside watching a woman. You are your own voyeur.",
    "https://www.goodreads.com/quotes/329778-male-fantasies-male-fantasies-is-everything-run-by-male-fantasies",
  ],
  [
    "Behind my eyelids are islands of violence / My mind shipwrecked, this is the only land my mind could find",
    "https://genius.com/Twenty-one-pilots-migraine-lyrics",
  ],
  [
    "'It might be a whole secret country,' she continued, 'and you and I would be the rulers of it.'",
    "https://www.goodreads.com/book/show/40940121-bridge-to-terabithia",
  ],
  [
    "I can make something good",
    "https://genius.com/Porter-robinson-look-at-the-sky-lyrics",
  ],
  [
    "This is how all our stories begin, in darkness with our eyes closed, and all our stories end the same way",
    "https://www.goodreads.com/book/show/65112.The_End",
  ],
  [
    "'You look like a friend,' said Corduroy. 'I've always wanted a friend.'",
    "https://www.goodreads.com/book/show/231850.Corduroy",
  ],
  [
    "Stories are compasses and architecture; we navigate by them, we build sanctuaries and prisons out of them. To love is to put yourself in their story",
    "https://www.goodreads.com/quotes/810174-stories-are-compasses-and-architecture-we-navigate-by-them-we",
  ],
  [
    "Our brains are sick but that's okay",
    "https://genius.com/Twenty-one-pilots-fake-you-out-lyrics",
  ],
  [
    "That's why we seize the moment, try to freeze it and own it / Squeeze it and hold it ‘cause we consider these minutes golden",
    "https://genius.com/Eminem-sing-for-the-moment-lyrics",
  ],
  [
    "I came of age in the Dream House, wisdom practically smothering me in my sleep. Everything tasted like an almost epiphany.",
    "https://www.goodreads.com/book/show/43317482-in-the-dream-house",
  ],
  [
    "As if happiness is an acquired taste, to which you become accustomed, but despair is something surprising each time you encounter it",
    "https://www.goodreads.com/book/show/65112.The_End",
  ],
  [
    "So much for endings. Beginnings are always more fun. True connoisseurs are known to favor the stretch in between, since it's the hardest to do anything with.",
    "https://www.goodreads.com/book/show/13250990-happy-endings",
  ],
  [
    "You are not yourself, you are crowds of others, you are as leaky a vessel as was ever made",
    "https://www.goodreads.com/book/show/16158561-the-faraway-nearby",
  ],
  [
    "I wanna fall inside your ghost / And fill up every hole inside my mind",
    "https://genius.com/Twenty-one-pilots-taxi-cab-lyrics",
  ],
  [
    "All the world's a stage / And all the men and women merely players",
    "https://en.wikipedia.org/wiki/All_the_world%27s_a_stage",
  ],
  [
    "For each man kills the thing he loves / Yet each man does not die",
    "https://poets.org/poem/ballad-reading-gaol",
  ],
  [
    "Are all things quantifiable, and all numbers fraught with poetic possibility?",
    "https://www.goodreads.com/book/show/3181564-the-housekeeper-and-the-professor",
  ],
  [
    "A thousand dreams I'm holding heavy",
    "https://genius.com/Foxes-youth-lyrics",
  ],
  [
    "Through the digital, the body in glitch finds its genesis",
    "https://www.goodreads.com/book/show/53076661-glitch-feminism",
  ],
  [
    "The self is a creation, the crafting of which makes everyone an artist... we are gods of the small universe of self and the large world of repercussions",
    "https://www.goodreads.com/book/show/16158561-the-faraway-nearby",
  ],
  [
    "All the ways of tending to the world less easily validated than parenting... the writing and inventing and the activism",
    "https://www.goodreads.com/book/show/29633797-the-mother-of-all-questions",
  ],
  [
    "But the old me is still me and maybe the real me / And I think she's pretty",
    "https://genius.com/Billie-eilish-skinny-lyrics",
  ],
  [
    "Radios playing a mash of songs about heartbreak and ruin, heartbreak and memory, heartbreak and hatred, how it’s the deeper intimacy",
    "https://granta.com/los-angeles-ma/",
  ],
  [
    "I'm looking for affection in all the wrong places / And we'll keep falling on each other to fill the empty spaces",
    "https://genius.com/Between-friends-affection-lyrics",
  ],
  [
    "We are survivors of each other. We have been shark to one another, but also lifeboat.",
    "https://www.goodreads.com/book/show/51019.Cat_s_Eye",
  ],
  [
    "And always when the flash came Emily felt that life was a mysterious thing of persistent beauty.",
    "https://www.goodreads.com/book/show/658552.Emily_of_New_Moon",
  ],
  [
    "This is what I keep returning to: how people decide who is or is not an unreliable narrator",
    "https://www.goodreads.com/book/show/43317482-in-the-dream-house",
  ],
  [
    "Time and space, creatures of infinite girth and tenderness, are keeping you safe as they were once unable to",
    "https://www.goodreads.com/book/show/43317482-in-the-dream-house",
  ],
  [
    "I am a forest fire / And I am the fire and I am the forest / And I am a witness watching it",
    "https://genius.com/Mitski-a-burning-hill-lyrics",
  ],
  [
    "If I can make my language flat enough, exact enough, if I can rinse each sentence clean enough, I could—it all could—just disappear.",
    "https://www.goodreads.com/book/show/263257.The_Red_Parts",
  ],
  [
    "Went looking for a creation myth / Ended up with a pair of cracked lips",
    "https://genius.com/Phoebe-bridgers-i-know-the-end-lyrics",
  ],
  [
    "All we are is an isle of flightless birds / We find our worth in giving birth and stuff",
    "https://genius.com/Twenty-one-pilots-isle-of-flightless-birds-lyrics",
  ],
  [
    "Once, I took your medication to know what it's like / And now I have to act like I can't read your mind",
    "https://genius.com/Boygenius-cool-about-it-lyrics",
  ],
  [
    "I am lonely with the loneliness of unshared thought",
    "https://www.goodreads.com/book/show/773507.Emily_s_Quest",
  ],
  [
    "What if computers care / And we just stare through their hearts all day?",
    "https://genius.com/Between-friends-better-friends-lyrics",
  ],
];
